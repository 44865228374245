<template>
  <div class="about">
    <img alt="Hoshino logo" src="@/assets/logo.webp" />
    <p>
      项目主页：<a
        href="https://github.com/Ice-Cirno/HoshinoBot"
        target="_blank"
        >github</a
      >
    </p>
    <p>
      NGA：<a href="https://ngabbs.com/read.php?tid=27674411" target="_blank"
        >讨论帖</a
      >
    </p>
    <p>
      赞助使用：<a href="https://jq.qq.com/?_wv=1027&k=eYGgrL4A" target="_blank"
        >Hoshinoのお茶会</a
      >
    </p>
    <p>
      开发交流：<a href="https://jq.qq.com/?_wv=1027&k=wgirhYYQ" target="_blank"
        >Hoshinoの后花园</a
      >
    </p>
  </div>
</template>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #5ac9eb;
}
</style>
